import { createPinia } from "pinia"
import { createApp } from "vue"
import { createI18n } from "vue-i18n"
import App from "./App.vue"
import router from "./routes"
import "./style.scss"
import { defaultLocale, languages } from "./i18n"
 

const app = createApp(App);


const messages = Object.assign(languages);
const pinia = createPinia();
const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: "en",
  messages,
});

app.use(pinia).use(i18n).use(router).mount("#app");
