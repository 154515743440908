import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/view/loader"),
  },

  {
    path: "/store",
    name: "store",
    component: () => import("@/view/store"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
